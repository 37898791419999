"use client";

import { Skeleton } from "primereact/skeleton";
import React from "react";
import Logo from "@/components/Public/Logo";

const Loading: React.FC = () => {
  return (
    <div>
      <Skeleton className="w-full h-screen" />
      <div>
        <div className="public-loading-logo">
          <div className="hidden md:block">
            <Logo height={80} width={300} />
          </div>
        </div>
        <div className="public-loading-logo small-screen">
          <div className="md:hidden lg:hidden xl:hidden">
            <Logo height={40} width={150} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
