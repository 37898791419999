import React from "react";
import Image from "next/image";

interface LogoProps {
  width?: number;
  height?: number;
}

const Logo = ({ width = 150, height = 40 }: LogoProps) => {
  return (
    <Image
      src="/images/logo/svg/logo-white-no-background-fire.svg"
      alt="logo"
      width={width}
      height={height}
      priority={true}
    />
  );
};

export default Logo;
